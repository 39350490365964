.ei_container {
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  min-height: 61.8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ei_content-container {
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  margin-right: 20%;
}


.ei_upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 100%;
}

.ei_upload-btn-container {
  width: 30%;
}

@media (max-width: 800px) {
  .ei_content-container {
    margin-left: 12px;
    margin-right: 12px;
  }


  .ei_upload-btn-container {
    width: 80%;
  }
}

.ei_upload-button {
  height: 60px;
  width: 100%;
  border-radius: 30px;
  background-color: #5adbb5;
  color: black;
  border: none;
  font-size: medium;
}

.ei_compare-button {
  height: 60px;
  width: 100%;
  margin-top: 12px;
  border-radius: 30px;
  background-color: #a2b132;
  color: black;
  border: none;
  font-size: medium;
}

h1 {
  font-size: 1.3em;
}

h4 {
  margin-bottom: 8px;
}

.ei_error {
  color: red;
  padding: 8px;
}

.ei_loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 100%;
}

.ei_loading {
  margin-top: 24px;
  height: 50px;
  width: 100%;
}

.ei_footer {
  height: 50px;
}

.ei_upload-disclaimer {
  margin-bottom: 24px;
}

.ei_preview-container {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.ei_preview-img {
  max-height: 250px;
  max-width: 100%;
  height: auto;
  width: auto;
}